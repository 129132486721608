import { useFollowUserMutation, useGetIsFollowingQuery } from '@/RTK/services/userApi';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

export const useFollow = (userId) => {
  const router = useRouter();
  const token = useSelector((state) => state.auth.token);
  const currentUser = useSelector((state) => state.auth.user);

  // Skip following check if no token or same user
  const { data: isFollowingData, isLoading: checkingFollowStatus } = useGetIsFollowingQuery(
    userId,
    {
      skip: !token || !userId || !currentUser?._id || userId === currentUser?._id,
      pollingInterval: 0
    }
  );

  // Skip follow mutation if no token
  const [followUser, { isLoading: followLoading }] = useFollowUserMutation({
    skip: !token
  });

  const handleFollow = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    
    if (!token) {
      router.push('/signin');
      return;
    }

    if (userId === currentUser?._id) return;

    try {
      await followUser(userId).unwrap();
    } catch (error) {
      console.error('Error following user:', error);
    }
  };

  return {
    isFollowing: isFollowingData?.isFollowing ?? false,
    handleFollow,
    isLoading: checkingFollowStatus || followLoading,
    canFollow: token && userId !== currentUser?._id
  };
};